import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DialogModule, TreeModule, BreadcrumbModule, MenuModule, TooltipModule, CalendarModule, InputMaskModule, AutoCompleteModule, GrowlModule } from 'primeng/primeng';
import { LoadingModule } from 'ngx-loading';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DropdownModule } from 'primeng/dropdown';

/* Components */
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { IncidentBellComponent } from './components/nav-bar/incident-bell/incident-bell.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { TreeComponent } from './components/tree/tree.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { CameraComponent } from './components/mosaic-cameras/camera/camera.component';
import { MosaicCamerasComponent } from './components/mosaic-cameras/mosaic-cameras.component';
import { CameraSelectorComponent } from './components/mosaic-cameras/camera/camera-selector/camera-selector.component';
import { MosaicSelectorComponent } from './components/mosaic-cameras/mosaic-selector/mosaic-selector.component';
import { IncidentCardComponent } from './components/incident-card/incident-card.component';
import { StepsModule } from './components/steps/steps.module';
import { EmpoweredInputComponent } from './components/empowered-input/empowered-input.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CardPersonBasicComponent } from './components/card-person-basic/card-person-basic.component';
import { CalendarWrapperComponent } from './components/calendar-wrapper/calendar-wrapper.component';
import { BlockedPeopleComponent } from './components/blocked-people/blocked-people.component';
import { CameraControlComponent } from './components/camera-control/camera-control.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { AccessCallBannerComponent } from './components/access-call-banner/access-call-banner.component';
import { AccessCallDetailsDrawerModule } from './components/access-call-details-drawer/access-call-details-drawer.module';
import { PulseComponent } from './components/pulse/pulse.component';
import { MonitoringCameraComponent } from './components/monitoring-camera/monitoring-camera.component';
import { CameraHistoryComponent } from './components/camera-history/camera-history.component';

/* Pipes */
import {
  PersonDocumentPipe,
  PersonDocumentAndDocumentTypePipe,
  PersonPipe,
  PersonDocumentTypePipe
} from './pipes/person-document-pipe';
import {
  DateFromNowPipe,
  DateFromNowRelativePipe
} from './pipes/date-from-now.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format-pipe';
import { MaskingPipe } from './pipes/masking-pipe';
import { ParseGMTPipe } from './pipes/parse-gmt.pipe';

/* Directives */
import { TextFillDirective } from './directives/text-fill.directive';
import { NumbersOnlyDirective } from './components/numbers-only/numbers-only.directive';
import { AlertSoundDialogComponent } from './components/alert-sound-dialog/alert-sound-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { SamDataCardModule } from '@shared/components/sam-data-card/sam-data-card.module';
import { EventTypeBadgeColorPipe } from './pipes/event-type-badge-color.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    TranslateModule,
    RouterModule,
    BreadcrumbModule,
    DialogModule,
    LoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(0,0,0,0)'
    }),
    TreeModule,
    MenuModule,
    StepsModule,
    InputMaskModule,
    TextMaskModule,
    CalendarModule,
    TooltipModule,
    AutoCompleteModule,
    GrowlModule,
    MatExpansionModule,
    MatRadioModule,
    DropdownModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    SamDataCardModule
  ],
  declarations: [
    SideBarComponent,
    IncidentBellComponent,
    PersonDocumentPipe,
    PersonDocumentAndDocumentTypePipe,
    PersonDocumentTypePipe,
    PersonPipe,
    MaskingPipe,
    DateFromNowPipe,
    DateFromNowRelativePipe,
    DateTimeFormatPipe,
    BreadcrumbComponent,
    DialogComponent,
    NavBarComponent,
    TreeComponent,
    PageErrorComponent,
    PageHeaderComponent,
    CameraComponent,
    CameraSelectorComponent,
    MosaicCamerasComponent,
    MosaicSelectorComponent,
    IncidentCardComponent,
    EmpoweredInputComponent,
    CalendarComponent,
    CardPersonBasicComponent,
    ParseGMTPipe,
    CalendarWrapperComponent,
    BlockedPeopleComponent,
    NumbersOnlyDirective,
    AccessCallBannerComponent,
    CameraControlComponent,
    TextFillDirective,
    CameraControlComponent,
    MultiSelectComponent,
    CameraControlComponent,
    PulseComponent,
    MonitoringCameraComponent,
    CameraHistoryComponent,
    AlertSoundDialogComponent,
    EventTypeBadgeColorPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideBarComponent,
    SamDataCardModule,
    IncidentBellComponent,
    CameraComponent,
    PersonDocumentPipe,
    PersonDocumentAndDocumentTypePipe,
    PersonDocumentTypePipe,
    PersonPipe,
    MaskingPipe,
    DateFromNowPipe,
    DateFromNowRelativePipe,
    DateTimeFormatPipe,
    EventTypeBadgeColorPipe,
    BreadcrumbComponent,
    DialogComponent,
    NavBarComponent,
    LoadingModule,
    TreeComponent,
    PageErrorComponent,
    PageHeaderComponent,
    CameraSelectorComponent,
    MosaicCamerasComponent,
    MosaicSelectorComponent,
    IncidentCardComponent,
    StepsModule,
    EmpoweredInputComponent,
    InputMaskModule,
    TextMaskModule,
    CalendarComponent,
    CalendarModule,
    CardPersonBasicComponent,
    AutoCompleteModule,
    ParseGMTPipe,
    CalendarWrapperComponent,
    BlockedPeopleComponent,
    NumbersOnlyDirective,
    AccessCallBannerComponent,
    GrowlModule,
    CameraControlComponent,
    AccessCallDetailsDrawerModule,
    MultiSelectComponent,
    CameraControlComponent,
    TextFillDirective,
    PulseComponent,
    MatExpansionModule,
    MatRadioModule,
    DropdownModule,
    MonitoringCameraComponent,
    CameraHistoryComponent,
    MatPaginatorModule,
    MatDialogModule,
    AlertSoundDialogComponent,
    MatButtonModule
  ],
  entryComponents: [
    AlertSoundDialogComponent
  ]
})
export class SharedModule { }
