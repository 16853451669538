import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { buffer, debounceTime, filter } from 'rxjs/operators';
import { Subject, Observable, merge, interval, of } from 'rxjs';
import * as SockJS from 'sockjs-client';
import * as WebStomp from 'webstomp-client';
import { environment } from '@environment/environment';
import { StorageService } from '@core/storage/storage.service';
import { GoogleAnalyticsService } from '@core/google-analytics/google-analytics.service';

@Injectable()
export class WebSocketService {

  static isDisconected = new EventEmitter<boolean>();

  // Faz o polling a cada 30 segundos para reconectar ou fazer polling. Evitando sobrecarregamento do servidor.
  private readonly pollingMiliseconds = 30000;
  private readonly throttlingTimeMiliseconds = 6000;
  private socket;
  private client: WebStomp.Client;
  private connected = false;
  private onConnect = new Subject();
  private onPolling: Observable<any>;
  private readonly topicSubscribe: any = new Map<string, any>();

  constructor(
    private cookieService: CookieService,
    private storageService: StorageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  public connect() {
    this.socket = new SockJS(`${this.getBaseUrl()}/websocket/subscription`);
    this.client = WebStomp.over(this.socket);
    this.onPolling = interval(this.pollingMiliseconds);
    this.connectWebSocketClient();
    this.socket.onclose = () => this.onError();
    this.socket.onerror = () => this.onError();
    this.onPolling.subscribe(() => this.connectWebSocketClient());
  }

  public onClose(topic: any, id: any): void {
    const topicId = `${topic}-${id}`;
    if (this.topicSubscribe.get(topicId)) {
      this.topicSubscribe.get(topicId).unsubscribe();
      this.topicSubscribe.delete(topicId);
    }
  }

  private onError() {
    this.connected = false;
    WebSocketService.isDisconected.emit(true);
    this.connectWebSocketClient();
    this.sendDisconectToGoogleAnalitics();
    setTimeout(() => {
      if (!this.connected) {
        window.location.reload();
      }
    }, this.pollingMiliseconds);
  }

  private async sendDisconectToGoogleAnalitics() {
    this.googleAnalyticsService.sendToAnalytics('intercaptor_error', {
      value: `WebSocket disconected`
    }, 'Monitor');
  }

  public observeTopic(topic: any, screen?: string): Observable<any> {
    if (this.socket) {
      const webSocketSubject = new Subject();
      if (this.connected) {
        this.subscribeToTopic(webSocketSubject, topic, screen);
      } else {
        this.onConnect
          .subscribe(() => this.subscribeToTopic(webSocketSubject, topic, screen));
      }
      webSocketSubject.next({});
      // Já executa imediatamente na primeira vez.
      const time = webSocketSubject.pipe(debounceTime(this.throttlingTimeMiliseconds));
      return merge(of({}), webSocketSubject.pipe(buffer(time)));
    } else {
      return of();
    }
  }

  filterTopicByPhysicalLocations(physicalLocationId: number | Array<number>) {
    // Utilitário para fazer o filtro de local físico no WebSocket
    return filter(topicResponse => this.isTopicResponseAboutPhysicalLocation(topicResponse, physicalLocationId));
  }

  private connectWebSocketClient() {
    if (!this.connected) {
      this.client.connect({}, () => {
        this.onConnect.next();
        this.connected = true;
        WebSocketService.isDisconected.emit(false);
      }, () => {
        if (!this.client.connected) {
          WebSocketService.isDisconected.emit(true);
          this.connected = false;
        }
      });
    }
  }

  private subscribeToTopic(subject: Subject<any>, topic: string, screen: string) {
    const topicId = `${topic}-${screen}`;
    const tenant = this.storageService.getTenant();
    this.topicSubscribe.set(topicId, this.client.subscribe(
      `/topic/${tenant.tenantDomain}/${environment.project.domain}/${environment.project.websocket_service}/${topic}`,
      response => subject.next(response),
      { id: topicId }
    ));
  }

  private getBaseUrl() {
    return this.cookieService.get('com.senior.base.url');
  }

  private isTopicResponseAboutPhysicalLocation(topicResponse: any, physicalLocationId: number | Array<number>) {
    if (topicResponse instanceof Array) {
      return topicResponse.find(topic => this.isTopicResponseAboutPhysicalLocation(topic, physicalLocationId)) !== undefined;
    }
    const topicResponseBody = topicResponse.body ? JSON.parse(topicResponse.body) : {};
    const hasPhysicalLocationId = topicResponseBody.data && (topicResponseBody.data.physicalLocationId.filter(topicPhysicalLocationId => {
      if (physicalLocationId instanceof Array) {
        return physicalLocationId.find(id => id === topicPhysicalLocationId) !== undefined;
      }
      return topicPhysicalLocationId === physicalLocationId;
    }).length !== 0);
    return Object.keys(topicResponse).length === 0 || hasPhysicalLocationId;
  }
}
